/* src/App.css */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

body {
  font-family: Arial, sans-serif;
  background-color: #fcfcfc;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
}

header {
  background-color: #4baac2;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

header button {
  background-color: #68b6c5;
  color: #fcfcfc;
  font-size: 16px;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
}

header button:hover {
  background-color: #85c2c9;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  height: 100%;
}

main {
  padding: 10px;
  overflow-y: auto;
  flex-grow: 1;
}

.messages {
  display: flex;
  flex-direction: column;
}

.ChatMessage {
  display: flex;
  margin-bottom: 10px;
}

.ChatMessage.user {
  justify-content: flex-end;
}

.ChatMessage.chatgpt {
  justify-content: flex-start;
}

.ChatMessage.user .message-text,
.ChatMessage.chatgpt .message-text {
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  max-width: 80%;
  word-wrap: break-word;
}

.ChatMessage.user .message-text {
  background-color: #0078d7;
  color: #ffffff;
}

.ChatMessage.chatgpt .message-text {
  background-color: #d0e6ff;
  color: #000000;
}

section {
  background-color: #a3cfcd;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  display: flex;
  width: 100%;
}

input[type='text'] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  outline: none;
}

button[type='submit'] {
  background-color: #4baac2;
  color: #fcfcfc;
  font-size: 16px;
  padding: 10px 12px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 10px;
  outline: none;
}

button[type='submit']:hover {
  background-color: #68b6c5;
}

.thinking {
  font-style: italic;
  color: #888888;
  margin-left: 5px;
}

/* Add this to your existing App.css file */

.sign-in {
  text-align: center;
  background-color: #a3cfcd;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  margin: 0 auto;
}

.sign-in h1 {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 20px;
}

.sign-in p {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 20px;
}
